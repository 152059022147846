@import '~animate.css';

@import '../assets/css/icomoon.min.css';

@import '../../node_modules/daterangepicker/daterangepicker.css';

@import '../assets/css/bootstrap.css';

@import '../assets/css/bootstrap_limitless.css';

@import '../assets/css/layout.css';

@import '../../node_modules/daterangepicker/daterangepicker.css';

@import '../assets/css/colors.css';

@import '../assets/css/components.css';

@import '../../node_modules/dropzone/dist/dropzone.css';

@import '../../node_modules/jodit/build/jodit.css';

@import '../assets/css/nestable.css';

:root {
  --primary: #00b7b1;
}

